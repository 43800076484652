<template>
  <Header />
  <slot />
  <Footer />
</template>

<script setup>
import Header from "@/components/Common/Header/Header";
import Footer from "@/components/Common/Footer/Footer";

import { onMounted } from "vue";

const searchStore = useSearch();

useAsyncData(async () => {
  if (!searchStore.serviceCategories.length) {
    await searchStore.getServiceCategories();
  }
});
</script>
